@font-face {
  font-family: "logotype";
  src: url("../public/fonts/LOGOTYPE.ttf");
}

body {
  margin: 0;
  font-family: nunito-sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
